import Markdown from 'marked-react';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useFlowContext } from '../../hooks/verification-flow';
import { eventFactory } from '../../utils/monitoring/event-factory';
import { sendEvent } from '../../utils/monitoring/send-bi';
import Button from '../button/button.component';
import Instruction from '../instruction/instruction.component';
import Text from '../text/text.component';
import Title from '../title/title.component';

import DocumentSvg from './document.svg';
import ExitSvg from './exit.svg';
import SelfieSvg from './selfie.svg';

import './consent-modal.component.scss';

interface ConsentModalProps {
  className?: string;
  handleAccept: () => void;
  clickedExit: () => void;
  isUsConsent?: boolean;
  isCustomConsent?: boolean;
  customConsentText?: string;
  customAdditionalConsentText?: string;
}

const ConsentModal: React.FC<ConsentModalProps> = ({
  className,
  handleAccept,
  clickedExit,
  isUsConsent,
  isCustomConsent,
  customConsentText,
  customAdditionalConsentText,
}) => {
  const { t } = useTranslation();
  const { state } = useFlowContext();

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleAccept();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      handleAccept();
    }
  };

  let consentModalBody;
  let consentText;
  if (isCustomConsent && customConsentText) {
    consentText = customAdditionalConsentText
      ? customAdditionalConsentText + '\n\n' + customConsentText
      : customConsentText;
    consentModalBody = (
      <Text className={'consent-message'} testId="consent-message">
        <Markdown>{consentText}</Markdown>
      </Text>
    );
  } else if (isUsConsent) {
    consentModalBody = (
      <Text className={'consent-message'} testId="consent-message">
        {customAdditionalConsentText && <Markdown>{customAdditionalConsentText + '\n\n'}</Markdown>}
        <Markdown>{t('consent_modal_us_version')}</Markdown>
      </Text>
    );
  } else {
    consentModalBody = (
      <div>
        <Text testId="consent-modal-sub-title" className={'consent-modal-sub-title'}>
          {t('consent_modal_sub_title')}
        </Text>
        <div className={'consent-icons'}>
          <Instruction
            testId="consent-icon-document"
            className={'document'}
            Icon={DocumentSvg}
            instructionTitle={t('document')}
          />
          <Instruction testId="consent-icon-selfie" Icon={SelfieSvg} instructionTitle={t('selfie')} />
        </div>
        <Text testId="consent-explanation" className={'consent-explanation'}>
          {t('consent_modal_explanation')}
        </Text>
        <div className={'consent-dpa'}>
          <Trans>
            <div>
              {t('transmit_security_privacy_statement')}{' '}
              <a
                href="https://www.transmitsecurity.com/legal/transmit-security-privacy-statement"
                target="_blank"
                rel="noreferrer"
              >
                {t('dpa')}
              </a>
              .
            </div>

            <div>{t('dpa_consent_explanation')}</div>
          </Trans>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (state.sessionId && className == 'show') {
      const eventReport = eventFactory.createPageViewEvent('consent');
      sendEvent(state.sessionId, eventReport);
    }
  }, [className]);

  return (
    <div className={`consent-modal-placement ${className ?? ''}`}>
      <div className={`consent-modal ${className ?? ''}`}>
        <div className={'consent-modal-head'}>
          <Title className={'consent-modal-title'}>{t('terms_and_conditions')}</Title>
          <Button className={'exit-button'} type={'link'} onClick={clickedExit}>
            <ExitSvg />
          </Button>
        </div>
        {consentModalBody}
        <div className={'continue-button'} onKeyDown={handleKeyDown}>
          <Button testId="accept-and-continue" onClick={handleButtonClick}>
            {t('accept_and_continue')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConsentModal;
