import { useMemo } from 'react';

interface ImageProps {
  src: string;
  className?: string;
  alt?: string;
  testid?: string;
}

const Image: React.FC<ImageProps> = ({ src, className, alt, testid }: ImageProps) => {
  const imageUrl = useMemo(() => {
    // forcing to fetch image from remote and not from cache due to a problem of logo not loaded
    return `${src}?v=${Math.random()}`;
  }, [src]);

  return (
    <img
      src={imageUrl}
      className={`image-component ${className ?? ''}`}
      data-testid={`${testid ?? ''}`}
      data-auto={`${testid ?? ''}`}
      alt={alt || 'image'}
    />
  );
};

export default Image;
