import CameraPhoto from 'jslib-html5-camera-photo';
import { createContext, Dispatch, useContext, useReducer, useRef } from 'react';

import { parseDataUri, selectCameraOnlyForKnownCases } from '../utils/camera-utils';

import { Image, RectangleCoords } from './verification-flow';

export type FacingMode = 'user' | 'environment';

type CurrentFrame = { parsedImageDataUri: Image | undefined; startTime: number };

const DEFAULT_VIDEO_CONSTRAINTS: MediaTrackConstraints = {
  width: { min: 640, max: 3840, ideal: 3840 },
  height: { min: 480, max: 2160, ideal: 2160 },
  frameRate: 30,
};
const SELFIE_VIDEO_CONSTRAINTS: MediaTrackConstraints = {
  width: { min: 640, max: 1280, ideal: 1280 },
  height: { min: 480, max: 720, ideal: 720 },
  frameRate: 30,
};
export interface CameraControls {
  start: (
    video: HTMLVideoElement,
    facingMode: FacingMode,
  ) => Promise<{
    mediaStream: Promise<MediaStream>;
    selectedCamera?: MediaDeviceInfo;
    optionalCameras?: MediaDeviceInfo[];
  }>;
  stop: () => Promise<void> | undefined;
  getCurrentFrame: (captureAreaCoords: RectangleCoords | undefined) => CurrentFrame | undefined;
}

export function useCameraFeed() {
  const cameraRef = useRef<CameraPhoto>();
  return {
    start: async (video: HTMLVideoElement, facingMode: FacingMode) => {
      cameraRef.current = new CameraPhoto(video);
      let deviceCamera;
      if (facingMode == 'environment') {
        deviceCamera = await selectCameraOnlyForKnownCases();
      }
      const selectedCamera = deviceCamera?.selectedCamera?.deviceId ?? facingMode;
      const videoConstrains = facingMode == 'environment' ? DEFAULT_VIDEO_CONSTRAINTS : SELFIE_VIDEO_CONSTRAINTS;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore types package is missing
      const mediaStream = cameraRef.current.startCamera(selectedCamera, videoConstrains);
      return {
        mediaStream,
        optionalCameras: deviceCamera?.optionalCameras,
        selectedCamera: deviceCamera?.selectedCamera,
      };
    },
    stop: () => {
      if (!cameraRef.current) {
        return;
      }
      cameraRef.current.videoElement.pause();
      return cameraRef.current.stopCamera().catch(() => {
        // Do nothing
      });
    },
    getCurrentFrame: (captureAreaCoords: RectangleCoords | undefined) => {
      if (!cameraRef.current) {
        console.error('No camera ref');
        return;
      }
      let parsedImageDataUri;
      const startTime = Date.now();
      try {
        const imageDataUri = cameraRef.current.getDataUri({
          sizeFactor: 1,
          imageType: 'jpg',
          imageCompression: 0,
          isImageMirror: false,
        });
        parsedImageDataUri = parseDataUri(imageDataUri, captureAreaCoords);
        return { parsedImageDataUri, startTime: startTime };
      } catch (error) {
        return;
      }
    },
  };
}

type CameraStatue = 'PENDING' | 'RECODING';
type Action = 'IDLE' | 'START_CAMERA' | 'STOP_CAMERA';

interface VerificationFlow {
  status?: CameraStatue;
  action: Action;
}
export type CameraContext = VerificationFlow;

const initialState: VerificationFlow = { status: 'PENDING', action: 'IDLE' };

const FlowContext = createContext<{ state: CameraContext; dispatch: Dispatch<CameraContext> } | undefined>(undefined);
const flowReducer = (state: CameraContext, newState: CameraContext): CameraContext => {
  return {
    ...state,
    status: newState.action === 'START_CAMERA' ? 'RECODING' : 'PENDING',
  };
};
// to be able to use a global/high level context we need to define that context. it could be globally (app root)
// or fragmented, the data will be divided into smaller pieces and will be provided for relevant parts in the application
const CameraContextProvider = ({ children, value }: { children: React.ReactNode; value?: CameraContext }) => {
  const [state, dispatch] = useReducer(flowReducer, value || initialState);
  return <FlowContext.Provider value={{ state, dispatch }}>{children}</FlowContext.Provider>;
};

const useCameraContext = () => {
  const context = useContext(FlowContext);
  if (context === undefined) {
    //if there is no value the hook is not being called within a function component that is rendered within a `CameraContextProvider`
    throw new Error('useFlowContext must be used within CameraContextProvider');
  }
  return context;
};

export { CameraContextProvider, useCameraContext };
