import React from 'react';

import SpinnerLoader from '../spinner-loader/spinner-loader.component';

import './loading-block.component.scss';

interface LoadingBlockComponentProps {
  className?: string;
}

const LoadingBlockComponent: React.FC<LoadingBlockComponentProps> = ({ className }) => {
  return (
    <div className={`loading-block ${className ?? ''}`}>
      <SpinnerLoader className={'spinner-loader'} />
    </div>
  );
};

export default LoadingBlockComponent;
