/**
 *
 * @param pathname the path to extract the param from
 * @param findPattern the pattern to find the param in
 * @returns param value
 */
export function pathParamExtractor(pathname: string, findPattern: string) {
  findPattern = findPattern.startsWith('/') ? findPattern : `/${findPattern}`;
  const path = pathname.split('/');
  const prefix = findPattern.split('/');
  const tokenInPath = (path.filter((x) => !prefix.includes(x)) || []).shift();
  return tokenInPath;
}

export function getParamFromUrl(paramName: string) {
  return pathParamExtractor(window.location.pathname, paramName);
}
