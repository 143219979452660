const TimeoutIconSvg = (): React.ReactElement => {
  return (
    <svg
      className="timeout-icon"
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1053_523)">
        <path
          d="M29 0C28.0031 0 27.1875 0.815625 27.1875 1.8125V12.6875C27.1875 13.6844 28.0031 14.5 29 14.5C29.9969 14.5 30.8125 13.6844 30.8125 12.6875V3.69297C43.9758 4.62187 54.375 15.5988 54.375 29C54.375 43.0129 43.0129 54.375 29 54.375C14.9871 54.375 3.625 43.0129 3.625 29C3.625 21.9879 6.46836 15.6555 11.0562 11.0562C11.7586 10.3539 11.7586 9.19844 11.0562 8.49609C10.3539 7.79375 9.19844 7.79375 8.49609 8.49609C3.25117 13.741 0 20.991 0 29C0 45.018 12.982 58 29 58C45.018 58 58 45.018 58 29C58 12.982 45.018 0 29 0Z"
          fill="#ED3232"
        />
        <path
          d="M29.2451 18.7449C30.2384 18.7449 31.0554 19.562 31.0554 20.5553L31.0554 35.9348C31.0554 36.9281 30.2384 37.7451 29.2451 37.7451C28.2519 37.7451 27.4348 36.9281 27.4348 35.9348L27.4348 20.5553C27.4348 19.562 28.2519 18.7449 29.2451 18.7449Z"
          fill="#ED3232"
        />
      </g>
      <defs>
        <clipPath id="clip0_1053_523">
          <rect width="58" height="58" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TimeoutIconSvg;
