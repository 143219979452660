import { addTag, init, routMonitoring } from './sentry';

export const uiMonitoringInit = (sessionReplay: Date | undefined) => {
  init(sessionReplay);
};

export const handleNewTenant = (tenantID: string) => {
  addTag('TenantID', tenantID);
};

export const handleNewSession = (sessionID: string) => {
  addTag('sessionID', sessionID);
};

export const startedSession = (startedSession: string) => {
  addTag('startedSession', startedSession);
};

export const hitConsent = (hitConsent: string) => {
  addTag('hitConsent', hitConsent);
};

export const addStatus = (status: string) => {
  addTag('status', status);
};

export const isRedirected = (isRedirected: string) => {
  addTag('isRedirected', isRedirected);
};

export const hasError = (hasError: string) => {
  addTag('hasError', hasError);
};

export const getCameraPermission = (getCameraPermission: string) => {
  addTag('getCameraPermission', getCameraPermission);
};

export const noPermissionRefreshButton = (noPermissionRefreshButton: string) => {
  addTag('noPermissionRefreshButton', noPermissionRefreshButton);
};

export const handleRoues = () => {
  return routMonitoring();
};
