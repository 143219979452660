import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';

import LoadingBlockComponent from '../components/loading-block/loading-block.component';
import MainLayout from '../components/main-layout/main-layout.component';
import { useFlowContext } from '../hooks/verification-flow';
import QrPage from '../pages/desktop-qr/qr';
import ErrorPage from '../pages/error-page/error.page';
import OnboardingInstructions from '../pages/onboarding-instructions/onboarding-instructions.page';
import Processing from '../pages/processing/processing.page';
import SdkProcessingPage from '../pages/processing/sdk-processing.page';
import TimeoutPage from '../pages/timeout-page/timeout.page';
import SdkCameraPermissionsErrorPage from '../pages/web-sdk/sdk-camera-permissions-error.page';
import SdkFlowPage from '../pages/web-sdk/sdk-flow.page';
import SdkOnboardingInstructions from '../pages/web-sdk/sdk-onboarding-instructions.page';
import { handleRoues } from '../utils/monitoring/init-ui-monitoring';

import { routes } from './routes.utils';

const LazyRetryPage = React.lazy(() => import('../pages/retry-page/retry.page'));
const LazyScanDocumentFrontPage = React.lazy(() => import('../pages/camera-page/document-front.page'));
const LazyScanDocumentBackPage = React.lazy(() => import('../pages/camera-page/document-back.page'));
const LazySelfiePage = React.lazy(() => import('../pages/camera-page/selfie.page'));

const LazyImageQualityAlertPage = React.lazy(() => import('../pages/image-quality-alert/image-quality-alert.page'));
const LazyImageLoaderPage = React.lazy(() => import('../pages/image-loader/image-loader.page'));
const LazyMobileVerificationCompletePage = React.lazy(
  () => import('../pages/verification-complete/mobile-verification-complete/mobile-verification-complete.page'),
);
const LazyDesktopVerificationCompletedPage = React.lazy(
  () => import('../pages/verification-complete/desktop-verification-completed/demo-verification-completed.page'),
);
const LazyClickToContinuePage = React.lazy(() => import('../pages/click-to-continue/click-to-continue.page'));
const LazyVerificationInProgressPage = React.lazy(
  () => import('../pages/verification-in-progress/verification-in-progress.page'),
);

const Routes = handleRoues();

const RootRoutes: React.FC = function () {
  const { state } = useFlowContext();

  return (
    <Suspense fallback={<LoadingBlockComponent />}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route
            path={routes.desktopVerificationCompleted.path}
            key={routes.desktopVerificationCompleted.label}
            element={<LazyDesktopVerificationCompletedPage />}
          />
          <Route
            path={routes.mobileVerificationComplete.path}
            key={routes.mobileVerificationComplete.label}
            element={<LazyMobileVerificationCompletePage />}
          />
          <Route path="/:startToken">
            <Route
              index={true}
              element={state.appSettings.isSDKEnabled ? <SdkOnboardingInstructions /> : <OnboardingInstructions />}
            />
            <Route path={routes.sdkFlow.path} element={<SdkFlowPage />} />
            <Route path={routes.retry.path} key={routes.retry.label} element={<LazyRetryPage />} />

            <Route
              path={routes.mobileVerificationComplete.path}
              key={routes.mobileVerificationComplete.label}
              element={<LazyMobileVerificationCompletePage />}
            />
            <Route path={routes.qr.path} key={routes.qr.label} element={<QrPage />} />
          </Route>
          <Route path="/session/:sid">
            <Route path={routes.sdkFlow.path} element={<SdkFlowPage />} />
            <Route path={routes.sdkProcessing.path} key={routes.sdkProcessing.label} element={<SdkProcessingPage />} />

            <Route path={routes.processing.path} key={routes.processing.label} element={<Processing />} />

            <Route
              path={routes.scanDocumentFront.path}
              key={routes.scanDocumentFront.label}
              element={<LazyScanDocumentFrontPage />}
            />
            <Route path={routes.imageLoader.path} key={routes.imageLoader.label} element={<LazyImageLoaderPage />} />

            <Route
              path={routes.scanDocumentBack.path}
              key={routes.scanDocumentBack.label}
              element={<LazyScanDocumentBackPage />}
            />

            <Route path={routes.selfieImage.path} key={routes.selfieImage.label} element={<LazySelfiePage />} />

            <Route
              path={routes.imageQualityAlert.path}
              key={routes.imageQualityAlert.label}
              element={<LazyImageQualityAlertPage />}
            />
            <Route path={routes.sdkCameraError.path} element={<SdkCameraPermissionsErrorPage />} />

            <Route path={routes.error.path} key={routes.error.label} element={<ErrorPage />} />
            <Route path={routes.timeout.path} key={routes.timeout.label} element={<TimeoutPage />} />
            <Route path={routes.retry.path} key={routes.retry.label} element={<LazyRetryPage />} />
            <Route
              path={routes.mobileVerificationComplete.path}
              key={routes.mobileVerificationComplete.label}
              element={<LazyMobileVerificationCompletePage />}
            />
            <Route
              path={routes.verificationInProgress.path}
              key={routes.verificationInProgress.label}
              element={<LazyVerificationInProgressPage />}
            />

            <Route
              path={routes.clickToContinue.path}
              key={routes.clickToContinue.label}
              element={<LazyClickToContinuePage />}
            />
          </Route>
          <Route path={'/*'} key="not_found" element={<ErrorPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RootRoutes;
