const CalendarOnboardingSvg = (): React.ReactElement => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="calendar-icon svg-primary-color"
    >
      <g clipPath="url(#clip0_3720_1495)">
        <path
          opacity="0.4"
          d="M5.34683 3.46484C3.61894 3.46484 2.21411 4.86967 2.21411 6.59756V25.3939C2.21411 27.1218 3.61894 28.5266 5.34683 28.5266H17.8777C18.0392 28.5266 18.2008 28.5119 18.3525 28.4923C16.1694 26.926 14.745 24.3708 14.745 21.478C14.745 17.5327 17.3931 14.2091 21.0104 13.1861V6.59756C21.0104 4.86967 19.6056 3.46484 17.8777 3.46484H5.34683ZM6.13001 9.73028H17.0945C17.5253 9.73028 17.8777 10.0827 17.8777 10.5135C17.8777 10.9442 17.5253 11.2966 17.0945 11.2966H6.13001C5.69926 11.2966 5.34683 10.9442 5.34683 10.5135C5.34683 10.0827 5.69926 9.73028 6.13001 9.73028ZM5.34683 15.2125C5.34683 14.7818 5.69926 14.4294 6.13001 14.4294H13.9618C14.3926 14.4294 14.745 14.7818 14.745 15.2125C14.745 15.6433 14.3926 15.9957 13.9618 15.9957H6.13001C5.69926 15.9957 5.34683 15.6433 5.34683 15.2125ZM5.34683 19.9116C5.34683 19.4809 5.69926 19.1284 6.13001 19.1284H10.8291C11.2598 19.1284 11.6123 19.4809 11.6123 19.9116C11.6123 20.3424 11.2598 20.6948 10.8291 20.6948H6.13001C5.69926 20.6948 5.34683 20.3424 5.34683 19.9116Z"
        />
        <path d="M23.3595 28.526C25.2288 28.526 27.0215 27.7834 28.3433 26.4616C29.6651 25.1398 30.4077 23.3471 30.4077 21.4778C30.4077 19.6085 29.6651 17.8158 28.3433 16.494C27.0215 15.1723 25.2288 14.4297 23.3595 14.4297C21.4903 14.4297 19.6975 15.1723 18.3758 16.494C17.054 17.8158 16.3114 19.6085 16.3114 21.4778C16.3114 23.3471 17.054 25.1398 18.3758 26.4616C19.6975 27.7834 21.4903 28.526 23.3595 28.526ZM26.6536 20.4646L23.1295 23.9887C22.826 24.2922 22.3268 24.2922 22.0233 23.9887L20.0655 22.0309C19.762 21.7274 19.762 21.2282 20.0655 20.9247C20.369 20.6213 20.8682 20.6213 21.1717 20.9247L22.5764 22.3295L25.5474 19.3585C25.8509 19.055 26.3501 19.055 26.6536 19.3585C26.957 19.6619 26.957 20.1612 26.6536 20.4646Z" />
      </g>
      <defs>
        <clipPath id="clip0_3720_1495">
          <rect width="28.1945" height="25.0618" fill="white" transform="translate(2.21411 3.46484)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalendarOnboardingSvg;
