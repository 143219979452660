const DocumentSvg = (): React.ReactElement => {
  return (
    <svg
      width="63"
      height="48"
      viewBox="0 0 63 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="document-icon svg-primary-color"
    >
      <path d="M19.5 24C23.2875 24 26.3571 20.9303 26.3571 17.1428C26.3571 13.3553 23.2875 10.2857 19.5 10.2857C15.7125 10.2857 12.6429 13.3553 12.6429 17.1428C12.6429 20.9303 15.7179 24 19.5 24ZM22.9286 27.4286H16.0714C11.3379 27.4286 7.5 31.2643 7.5 36C7.5 36.9428 8.26714 37.7143 9.21429 37.7143H29.7857C30.7324 37.7143 31.5 36.9467 31.5 36C31.5 31.2643 27.6643 27.4286 22.9286 27.4286Z" />
      <path
        opacity="0.4"
        d="M55.5 0H7.49996C3.71246 0 0.642822 3.06964 0.642822 6.85714V41.1429C0.642822 44.9304 3.71246 48 7.49996 48H55.5C59.2875 48 62.3571 44.9304 62.3571 41.1429V6.85714C62.3571 3.06964 59.2821 0 55.5 0ZM19.5 10.2857C23.2875 10.2857 26.3571 13.3554 26.3571 17.1429C26.3571 20.9304 23.2875 24 19.5 24C15.7125 24 12.6428 20.9304 12.6428 17.1429C12.6428 13.3554 15.7178 10.2857 19.5 10.2857ZM29.7857 37.7143H9.21425C8.26711 37.7143 7.49996 36.9429 7.49996 36C7.49996 31.2643 11.3378 27.4286 16.0714 27.4286H22.9285C27.6621 27.4286 31.5 31.2664 31.5 36C31.5 36.9429 30.7285 37.7143 29.7857 37.7143ZM53.7857 30.8571H40.0714C39.1285 30.8571 38.3571 30.0857 38.3571 29.1429C38.3571 28.2 39.1285 27.4286 40.0714 27.4286H53.7857C54.7285 27.4286 55.5 28.2 55.5 29.1429C55.5 30.0857 54.7285 30.8571 53.7857 30.8571ZM53.7857 24H40.0714C39.1285 24 38.3571 23.2286 38.3571 22.2857C38.3571 21.3429 39.1285 20.5714 40.0714 20.5714H53.7857C54.7285 20.5714 55.5 21.3429 55.5 22.2857C55.5 23.2286 54.7285 24 53.7857 24ZM53.7857 17.1429H40.0714C39.1285 17.1429 38.3571 16.3714 38.3571 15.4286C38.3571 14.4857 39.1285 13.7143 40.0714 13.7143H53.7857C54.7285 13.7143 55.5 14.4857 55.5 15.4286C55.5 16.3714 54.7285 17.1429 53.7857 17.1429Z"
      />
    </svg>
  );
};

export default DocumentSvg;
