import { useEffect } from 'react';

import { isDebuggerEnabled } from '../utils/is-debugger-enabled';
import { captureMessage } from '../utils/monitoring/sentry';

const useLifeCycles = (mount: () => void, unmount: () => void) => {
  useEffect(() => {
    if (mount) mount();
    return () => {
      if (unmount) unmount();
    };
  }, []);
};

export const useLogger = (name?: string, message?: string, props?: unknown[]) => {
  const isDebugger = isDebuggerEnabled();

  const logMessage = (message: string, ...optionalParams: unknown[]) => {
    if (!isDebugger) return;
    console.log(message, ...optionalParams);
    captureMessage(message);
  };
  useLifeCycles(
    () => name && logMessage(`${name}; mounted; ${message ?? ''}`),
    () => name && logMessage(`${name}; un-mounted; ${message ?? ''}`),
  );

  if (name) logMessage(`${name}; ${message ?? ''}`, props);

  return {
    logMessage,
  };
};
