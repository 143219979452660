import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useLogger } from '../../hooks/use-logger';
import { useFlowContext } from '../../hooks/verification-flow';

const SdkFlowPage: React.FC = () => {
  const { logMessage } = useLogger('');
  const { state } = useFlowContext();
  const { sid = '' } = useParams();
  useEffect(() => {
    if (sid && !state.sessionId && state.isWebSdkInitialized) {
      logMessage('page reloaded');
      window.tsPlatform.idv.start('');
    }
  }, [state.isWebSdkInitialized]);

  return <></>;
};

export default SdkFlowPage;
