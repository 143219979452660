import Image from '../image/image.component';

import './header.component.scss';

interface HeaderProps {
  className?: string;
  logoUrl: string;
}

const Header: React.FC<HeaderProps> = ({ className, logoUrl }: HeaderProps) => {
  return (
    <div className={`header-component logo-wrapper ${className ?? ''}`} data-auto="logo-wrapper">
      <Image testid="logo-header" className="logo-image" src={logoUrl} alt="logo" />
    </div>
  );
};
export default Header;
