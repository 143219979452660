import React from 'react';

import './text.component.scss';

interface TextComponentProps {
  children: React.ReactNode;
  type?: keyof HTMLElementTagNameMap;
  testId: string;
  className?: string;
  hidden?: boolean;
  strong?: boolean;
}

const Text: React.FC<TextComponentProps> = ({ strong, type = 'span', testId, children, ...props }) => {
  let elementType: keyof HTMLElementTagNameMap = 'span';
  if (strong) {
    elementType = 'strong';
  }
  if (type === 'p') {
    elementType = 'p';
  }
  return React.createElement(elementType, { ...props, 'data-auto': testId }, children);
};

Text.defaultProps = {
  type: 'span',
  className: '',
};

export default Text;
