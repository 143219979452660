import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from '../text/text.component';

import './instruction.component.scss';

const Instruction: React.FC<{
  Icon: React.FunctionComponent;
  instructionTitle: string;
  className?: string;
  testId?: string;
}> = ({ className, Icon, instructionTitle, testId }) => {
  const { t } = useTranslation();
  return (
    <div className={`instruction ${className ?? ''}`} data-auto={testId}>
      <div className={'instruction-icon-wrapper main-content-section'}>
        <Icon />
      </div>
      <div className={'details-section'}>
        <Text testId="instruction-title" className={'instruction-title'}>
          {t(instructionTitle)}
        </Text>
      </div>
    </div>
  );
};

export default Instruction;
