const ErrorIconSvg = (): React.ReactElement => {
  return (
    <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5909 40.7692C32.5742 40.7692 41.4758 31.8678 41.4758 20.8846C41.4758 9.9015 32.5742 1.00003 21.5909 1.00003C10.6076 1.00003 1.70605 9.9015 1.70605 20.8846C1.70605 31.8678 10.6076 40.7692 21.5909 40.7692Z"
        fill="#ED3232"
      />
      <path
        d="M21.4088 41.4336C32.6315 41.4336 41.7273 32.338 41.7273 21.1154C41.7273 9.89278 32.6315 0.797171 21.4088 0.797171C10.186 0.797171 1.09029 9.89278 1.09029 21.1154C1.09029 32.338 10.186 41.4336 21.4088 41.4336ZM21.4088 11.6067C22.2024 11.6067 22.8394 12.2437 22.8394 13.0373V21.7368C22.8394 22.5304 22.2024 23.1674 21.4088 23.1674C20.6152 23.1674 19.9782 22.5304 19.9782 21.7368V13.0373C19.9782 12.2437 20.6152 11.6067 21.4088 11.6067ZM23.4608 28.5721C23.4608 29.7075 22.5441 30.6241 21.4088 30.6241C20.2734 30.6241 19.3568 29.7075 19.3568 28.5721C19.3568 27.4368 20.2734 26.5202 21.4088 26.5202C22.5441 26.5202 23.4608 27.4368 23.4608 28.5721Z"
        fill="white"
        stroke="#ED3232"
        strokeWidth="0.867268"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ErrorIconSvg;
