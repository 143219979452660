import { useEffect, useState } from 'react';

import { isMobileDevice } from '../utils/is-mobile-device';

export function mobileDetection() {
  // apply static value coming outside of react's component lifecycle of is or is not mobile device
  const [isMobileAgent, setIsMobileAgent] = useState<boolean>(isMobileDevice());

  function handleWindowSizeChange() {
    // dynamically validate the isMobile detection
    setIsMobileAgent(isMobileDevice());
  }

  useEffect(() => {
    try {
      // dynamically validate the window resize event
      window.addEventListener('resize', handleWindowSizeChange);
    } catch (e) {
      console.error('Error:', e);
    }
    return () => {
      // dynamically validate the window resize event
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return { isMobile: isMobileAgent };
}
