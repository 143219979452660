const SelfieSvg = (): React.ReactElement => {
  return (
    <svg
      width="37"
      height="48"
      viewBox="0 0 37 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="selfie-icon svg-primary-color"
    >
      <g clipPath="url(#clip0_611_2915)">
        <path d="M21.7856 27H15.7856C11.6438 27 8.28564 30.3581 8.28564 34.5C8.28564 35.325 8.95689 36 9.78564 36H27.7856C28.614 36 29.2856 35.3284 29.2856 34.5C29.2856 30.3562 25.9294 27 21.7856 27ZM18.7856 24C22.0997 24 24.7856 21.3141 24.7856 18C24.7856 14.6859 22.0997 12 18.7856 12C15.4716 12 12.7856 14.6906 12.7856 18C12.7856 21.3094 15.4763 24 18.7856 24Z" />
        <path
          opacity="0.4"
          d="M32.2856 0H5.28564C2.80033 0 0.785645 2.01469 0.785645 4.5V43.5C0.785645 45.9844 2.80033 48 5.28564 48H32.2856C34.771 48 36.7856 45.9853 36.7856 43.5V4.5C36.7856 2.01469 34.77 0 32.2856 0ZM18.7856 12C22.0997 12 24.7856 14.6859 24.7856 18C24.7856 21.3141 22.0997 24 18.7856 24C15.4716 24 12.7856 21.3094 12.7856 18C12.7856 14.6906 15.4763 12 18.7856 12ZM27.7856 36H9.78564C8.95727 36 8.28564 35.3284 8.28564 34.5C8.28564 30.3563 11.6419 27 15.7856 27H21.7856C25.9275 27 29.2856 30.3581 29.2856 34.5C29.2856 35.325 28.6106 36 27.7856 36Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_611_2915">
          <rect width="36" height="48" fill="white" transform="translate(0.785645)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SelfieSvg;
