/**
 * format URL fragments to ensure correct path, so when both `api/action/something` and  `/api/action/something` are valid inputs
 * @param path partial URL
 * @param search optional search params
 * @returns string
 */
export function urlFragmentFormat(path: string, search?: URLSearchParams): string {
  if (!path?.trim()) {
    return '';
  }
  const mockDom = 'http://mock.com';
  const mockURL = new URL(mockDom);
  mockURL.search = search?.toString() || '';
  mockURL.pathname = path;
  return mockURL.href.replace(mockDom, '');
}

export function urlFormat(url: URL, pathname: string): URL {
  let path = url.pathname;
  path = path.endsWith('/') ? path.slice(0, -1) : path;
  pathname = pathname.startsWith('/') ? pathname.slice(1) : pathname;
  url.pathname = `${path}/${pathname}`;
  return new URL(url.href);
}

export function objectToUrlSearchParams(obj: { [key: string]: string }): URLSearchParams {
  const searchParams = new URLSearchParams();
  Object.keys(obj).forEach((key) => {
    searchParams.set(key, obj[key]);
  });
  return searchParams;
}
