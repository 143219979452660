import React from 'react';

import './button.component.scss';
type NativeButtonTypes = 'submit' | 'reset' | 'button' | undefined;
type OtherButtonTypes =
  | 'default'
  | 'primary'
  | 'ghost'
  | 'dashed'
  | 'link'
  | 'text'
  | 'default'
  | 'primary'
  | 'ghost'
  | 'dashed'
  | 'link'
  | 'text';
interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  testId?: string;
  label?: string;
  disabled?: boolean;
  type?: OtherButtonTypes | NativeButtonTypes;
}

const optNativeHtmlButtonTypes: (string | undefined)[] = ['submit', 'reset', 'button', undefined];

const Button: React.FC<ButtonProps> = function ({
  onClick,
  children,
  type = 'button',
  className,
  disabled,
  testId,
  ...props
}) {
  const buttonType = ((optNativeHtmlButtonTypes.includes(type) && type) || 'button') as NativeButtonTypes;
  const button = (
    <button
      {...props}
      className={`button-component ${className ?? ''} ${type ?? ''}`}
      onClick={onClick}
      disabled={disabled}
      data-testid={`${testId ?? ''}`}
      data-auto={`${testId ?? ''}`}
      type={buttonType}
    >
      {children}
    </button>
  );
  return button;
};

export default Button;
