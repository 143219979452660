export const pathPrefix = '/verify/app/';
export function baseUrlPath() {
  try {
    return isEnvDev() ? '' : pathPrefix;
  } catch (e) {
    return pathPrefix;
  }
}

export const isEnvDev = () => {
  try {
    return import.meta.env.VITE_ENV_TYPE === 'development';
  } catch (e) {
    throw new Error('VITE_ENV_TYPE is not defined');
  }
};

export const isEnvTest = () => {
  try {
    return (
      import.meta.env.VITE_ENV_TYPE === 'test' ||
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    );
  } catch (e) {
    throw new Error('VITE_ENV_TYPE is not defined');
  }
};
