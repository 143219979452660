const IdCardOnboardingSvg = (): React.ReactElement => {
  return (
    <svg
      viewBox="0 0 158 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="instruction-icon svg-primary-color"
    >
      <g clipPath="url(#clip0_232_856)">
        <path d="M65.625 96.25H30.625C23.3762 96.25 17.5 102.129 17.5 109.375C17.5 111.781 19.4578 113.75 21.875 113.75H74.375C76.7911 113.75 78.75 111.791 78.75 109.375C78.75 102.129 72.8711 96.25 65.625 96.25ZM65.625 70C65.625 60.3367 57.791 52.5 48.125 52.5C38.459 52.5 30.625 60.3367 30.625 70C30.625 79.6633 38.459 87.5 48.125 87.5C57.791 87.5 65.625 79.6523 65.625 70ZM144.375 8.75H13.125C5.87617 8.75 0 14.6262 0 21.875V35H157.5V21.875C157.5 14.6262 151.621 8.75 144.375 8.75Z" />
        <path
          opacity="0.4"
          d="M0 35V118.125C0 125.371 5.87617 131.25 13.125 131.25H144.375C151.624 131.25 157.5 125.374 157.5 118.125V35H0ZM48.125 52.5C57.791 52.5 65.625 60.3367 65.625 70C65.625 79.6633 57.791 87.5 48.125 87.5C38.459 87.5 30.625 79.6633 30.625 70C30.625 60.3367 38.4727 52.5 48.125 52.5ZM74.375 113.75H21.875C19.4578 113.75 17.5 111.781 17.5 109.375C17.5 102.129 23.3762 96.25 30.625 96.25H65.625C72.8738 96.25 78.75 102.126 78.75 109.375C78.75 111.781 76.7813 113.75 74.375 113.75ZM135.625 96.25H100.625C98.2089 96.25 96.25 94.2911 96.25 91.875C96.25 89.4589 98.2089 87.5 100.625 87.5H135.625C138.041 87.5 140 89.4589 140 91.875C140 94.2911 138.031 96.25 135.625 96.25ZM135.625 78.75H100.625C98.2188 78.75 96.25 76.7812 96.25 74.375C96.25 71.9688 98.2188 70 100.625 70H135.625C138.031 70 140 71.9688 140 74.375C140 76.7812 138.031 78.75 135.625 78.75ZM135.625 61.25H100.625C98.2188 61.25 96.25 59.2812 96.25 56.875C96.25 54.4688 98.2188 52.5 100.625 52.5H135.625C138.031 52.5 140 54.4688 140 56.875C140 59.2812 138.031 61.25 135.625 61.25Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_232_856">
          <rect width="157.5" height="140" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IdCardOnboardingSvg;
