import React, { ReactNode } from 'react';

import './page.component.scss';

interface PageComponentProps {
  className?: string;
  title?: ReactNode;
  footer?: ReactNode;
  verticalCenter?: boolean;
}

const PageComponent: React.FC<PageComponentProps> = function ({ className, children, title, footer, verticalCenter }) {
  const inlineStyle = { height: '' };

  if (verticalCenter) {
    inlineStyle.height = 'auto';
  }

  return (
    <div className={`page-component page-layout ${className ?? ''}`} style={inlineStyle}>
      {title && <header className={'z-top'}>{title}</header>}
      <main>{children}</main>
      {footer && <footer className={'z-top'}>{footer}</footer>}
    </div>
  );
};

export default PageComponent;
