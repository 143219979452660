import { UAParser } from 'ua-parser-js';

import { Image, RectangleCoords } from '../hooks/verification-flow';

export const parseDataUri = (dataUri: string, captureAreaCoords: RectangleCoords | undefined): Image => {
  if (!dataUri) throw new Error('dataUri is undefined or empty');
  const [formatData, imageData] = dataUri.split(',');
  if (!formatData) throw new Error('formatData is empty');
  const splitFormatData = formatData.split(';');
  const mimeType = splitFormatData?.[0]?.split(':')?.[1];
  const imageFormat = mimeType.split('/')?.[1];
  if (!imageFormat) throw new Error('imageFormat is empty');
  return {
    data: imageData,
    format: imageFormat,
    captureMargins: captureAreaCoords,
  };
};

export const buildDatUri = (image: Image): string => `data:image/${image.format};base64,${image.data}`;

// reference - https://github.com/BlinkID/blinkid-in-browser/blob/c59900e573e7a40e2c3a23f06f05309a4b25cd9e/src/MicroblinkSDK/CameraUtils.ts#L139

export async function selectCamera(): Promise<{
  optionalCameras: MediaDeviceInfo[];
  selectedCamera?: MediaDeviceInfo;
}> {
  const backCameras = await getCameraDevices();
  let camerasPool;
  if (backCameras.length > 0) {
    // sort camera pool by label
    camerasPool = backCameras.sort((camera1, camera2) => camera1.label.localeCompare(camera2.label));

    // Check if cameras are labeled with resolution information, take the higher-resolution one in that case
    // Otherwise pick the first camera
    let selectedCameraIndex = 0;

    const cameraResolutions: number[] = camerasPool.map((camera) => {
      const regExp = RegExp(/\b([0-9]+)MP?\b/, 'i');
      const match = regExp.exec(camera.label);
      if (match !== null) {
        return parseInt(match[1], 10);
      } else {
        return NaN;
      }
    });
    if (!cameraResolutions.some((cameraResolution) => isNaN(cameraResolution))) {
      selectedCameraIndex = cameraResolutions.lastIndexOf(Math.max(...cameraResolutions));
    }
    return { optionalCameras: backCameras, selectedCamera: camerasPool[selectedCameraIndex] };
  } else {
    return { optionalCameras: backCameras };
  }
}

export async function getCameraDevices(): Promise<MediaDeviceInfo[]> {
  const backCameras: MediaDeviceInfo[] = [];
  const devices = await enumerateDevices();
  const cameras = devices.filter((device) => device.kind === 'videoinput');
  for (const camera of cameras) {
    if (isBackCameraLabel(camera.label)) {
      backCameras.push(camera);
    }
  }
  return backCameras;
}
const backCameraKeywords: string[] = [
  'rear',
  'back',
  'rück',
  'arrière',
  'trasera',
  'trás',
  'traseira',
  'posteriore',
  '后面',
  '後面',
  '背面',
  '后置', // alternative
  '後置', // alternative
  '背置', // alternative
  'задней',
  'الخلفية',
  '후',
  'arka',
  'achterzijde',
  'หลัง',
  'baksidan',
  'bagside',
  'sau',
  'bak',
  'tylny',
  'takakamera',
  'belakang',
  'אחורית',
  'πίσω',
  'spate',
  'hátsó',
  'zadní',
  'darrere',
  'zadná',
  'задня',
  'stražnja',
  'belakang',
  'बैक',
  'camera2 0, facing back', // original source contain a new line after the comma: ,
];

// use to align between our hardcoded list of cameras to the source of the device name that we get so the filtering will work even if the source provide device name with new line or empty spaces
export function parseDeviceIdString(label: string): string {
  const toLowerCase = label.toLowerCase();
  const removeNewLines = toLowerCase.replace(/\r?\n|\r/g, '');
  const trimWhiteSpaces = removeNewLines.trim();
  return trimWhiteSpaces;
}

function isBackCameraLabel(label: string): boolean {
  const parsedLabel = parseDeviceIdString(label);

  return backCameraKeywords.some((availableCameras) => parsedLabel.includes(parseDeviceIdString(availableCameras)));
}

async function enumerateDevices() {
  let devices = await navigator.mediaDevices.enumerateDevices();

  if (devices.filter((device) => device.kind === 'videoinput').every((device) => device.label === '')) {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: { ideal: 'environment' },
      },
      audio: false,
    });

    // enumerate devices again and get their labels - now the label field should be non-empty, as we have a stream active
    // (even if we didn't get persistent permission for camera)
    devices = await navigator.mediaDevices.enumerateDevices();
    // close the stream, as we don't need it anymore
    stream.getTracks().forEach((track) => track.stop());
  }

  return devices;
}

const knownPreferredBackCamerasInfo: {
  os: { name: string };
  label: string;
}[] = [{ os: { name: 'Android' }, label: '2 0' }];

export async function selectCameraOnlyForKnownCases(): Promise<{
  optionalCameras: MediaDeviceInfo[];
  selectedCamera?: MediaDeviceInfo;
}> {
  const devices = await enumerateDevices();
  const backCameras = devices.filter((device) => {
    if (!device?.label || device.kind !== 'videoinput' || device.label === '') {
      return false;
    }
    const lowercaseLabel: string = device.label.toLowerCase();
    return backCameraKeywords.some((keyword) => lowercaseLabel.includes(keyword));
  });

  const currentUa = UAParser();
  const knownPreferredBackCamera = backCameras.find((backCamera) => {
    return knownPreferredBackCamerasInfo.find(
      (knownPreferredBackCamera) =>
        knownPreferredBackCamera.os.name === currentUa.os.name &&
        backCamera.label.includes(knownPreferredBackCamera.label),
    );
  });

  if (knownPreferredBackCamera) {
    return { optionalCameras: backCameras, selectedCamera: knownPreferredBackCamera };
  }
  return { optionalCameras: backCameras };
}
