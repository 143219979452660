import { httpPost } from '../http';

import { addTag, captureException } from './sentry';

type EventReport = {
  event_type: string;
  [key: string]: unknown;
};

export async function sendEvent(sid: string, eventReport: EventReport): Promise<void> {
  addTag('bi-event-type', eventReport.event_type);
  httpPost(`/verify/api/v1/verification/${sid}/events`, eventReport, true)
    .then()
    .catch((error) => {
      console.error('Unable to send BI event', error);
      captureException(new Error('Unable to send BI event'), {
        extra: { ...eventReport, path: `/verify/api/v1/verification/${sid}/events`, httpMethod: 'POST' },
      });
    });
}
