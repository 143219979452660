const CameraPermissionsSvg = (): React.ReactElement => {
  return (
    <div>
      <svg
        width="94"
        height="87"
        viewBox="0 0 94 87"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="instruction-icon svg-primary-color stroke"
      >
        <g clipPath="url(#clip0_194_3102)">
          <path d="M28.8461 4.74346H47.0492C50.1176 4.74346 52.8302 6.7031 53.7938 9.60846L55.3354 14.2304H66.4084C71.641 14.2304 75.8953 18.4847 75.8953 23.7173V61.6649C75.8953 66.8975 71.641 71.1518 66.4084 71.1518H9.48691C4.24688 71.1518 0 66.8975 0 61.6649V23.7173C0 18.4847 4.24688 14.2304 9.48691 14.2304H20.5599L22.1015 9.60846C23.0651 6.7031 25.7777 4.74346 28.8461 4.74346ZM37.9476 56.9215C45.804 56.9215 52.178 50.5474 52.178 42.6911C52.178 34.7013 45.804 28.4607 37.9476 28.4607C29.9579 28.4607 23.7173 34.7013 23.7173 42.6911C23.7173 50.5474 29.9579 56.9215 37.9476 56.9215Z" />
          <path
            opacity="0.4"
            d="M52.178 42.6911C52.178 50.5475 45.804 56.9215 37.9477 56.9215C29.9579 56.9215 23.7173 50.5475 23.7173 42.6911C23.7173 34.7013 29.9579 28.4607 37.9477 28.4607C45.804 28.4607 52.178 34.7013 52.178 42.6911Z"
          />
        </g>
        <circle cx="72.9052" cy="66.0113" r="20.2416" fill="white" />
        <g clipPath="url(#clip1_194_3102)">
          <path d="M72.7056 57.6125C70.9394 57.6125 69.5088 59.0577 69.5088 60.8401V62.7767H66.9513V60.8401C66.9513 57.6315 69.5288 55.0304 72.7056 55.0304C75.8825 55.0304 78.46 57.6315 78.46 60.8401V62.7767H75.9025V60.8401C75.9025 59.0577 74.4719 57.6125 72.7056 57.6125Z" />
        </g>
        <path
          className="svg-primary-color-50"
          d="M79.0994 62.6045C80.51 62.6045 81.6569 63.7624 81.6569 65.1866V72.9328C81.6569 74.357 80.51 75.5149 79.0994 75.5149H66.3119C64.8993 75.5149 63.7545 74.357 63.7545 72.9328V65.1866C63.7545 63.7624 64.6151 62.6045 66.3119 62.6045H79.0994ZM73.9844 67.7687C73.9844 67.0546 73.413 66.4776 72.7057 66.4776C71.9984 66.4776 71.4269 67.0546 71.4269 67.7687V70.3507C71.4269 71.0648 71.9984 71.6418 72.7057 71.6418C73.413 71.6418 73.9844 71.0648 73.9844 70.3507V67.7687Z"
        />
        <defs>
          <clipPath id="clip0_194_3102">
            <rect width="75.8953" height="75.8953" fill="white" />
          </clipPath>
          <clipPath id="clip1_194_3102">
            <rect width="17.9024" height="20.6566" fill="white" transform="translate(63.7545 55.0304)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CameraPermissionsSvg;
