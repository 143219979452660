import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { mockUiConfig } from '../__mocks__/mockData';

import { CameraContextProvider } from './hooks/camera-feed';
import { mobileDetection } from './hooks/device-detection';
import { AppSettings, FlowContext, FlowContextProvider, VerificationStartResponse } from './hooks/verification-flow';
import ErrorPage from './pages/error-page/error.page';
import { baseUrlPath, isEnvDev, isEnvTest } from './utils/base-url-path';
import { extractStartTokenFromUrl } from './utils/extract-from-url';
import { httpPost } from './utils/http';
import { initLng } from './utils/initLng';
import { isDebuggerEnabled, setDebuggerEnabled } from './utils/is-debugger-enabled';
import { handleNewTenant, uiMonitoringInit } from './utils/monitoring/init-ui-monitoring';
import { isStartToken } from './utils/validations';
import App from './app';

import './index.css';

/*
 * this will allow us to easily mock the uiConfig in dev mode
 * and to avoid the need to run the backend locally.
 * add the following to your .env file:
 * VITE_ENV_TYPE='development'
 *
 * modify end-user-verification/__mocks__/mockData.ts as needed
 */
if (isEnvDev()) {
  window.uiConfig = mockUiConfig;
}
let appSettingsOverride: Pick<AppSettings, 'logoUrl'> | undefined;
if (isEnvTest() && localStorage?.mockAppSettings) {
  try {
    appSettingsOverride = JSON.parse(localStorage?.mockAppSettings);
    window.uiConfig.settings.logoUrl = appSettingsOverride?.logoUrl;
  } catch (e) {
    // ignore error
  }
}

const QR_SCREEN_POLLING_INTERVAL_DEFAULT = 1000;

const getInitialApplicationConfiguration = () => {
  const uiConfig = window.uiConfig;
  const appSettings = (uiConfig?.settings as AppSettings) || {};
  const params = new URLSearchParams(window.location.search);
  const isStartedOnDesktop = params.get('startedOnDesktop') ? true : false;
  const isDebugger = isDebuggerEnabled();
  if (isDebugger) {
    setDebuggerEnabled();
  }
  const flowContextInitialValue = {
    isStartedOnDesktop,
    appSettings,
    commitHash: uiConfig?.commitHash,
    status: uiConfig?.status,
    missingImages: uiConfig?.missingImages,
    clientId: uiConfig?.clientId,
    tenantId: uiConfig?.tenantId,
    isDebuggerEnabled: isDebugger,
    qrScreenPollingInterval: uiConfig?.qrScreenPollingInterval || QR_SCREEN_POLLING_INTERVAL_DEFAULT,
  } as FlowContext;

  return flowContextInitialValue;
};

uiMonitoringInit(window.uiConfig?.settings?.sessionReplay);
initLng(window.uiConfig?.settings?.lng);

const AppWithContext = () => {
  const { isMobile } = mobileDetection();
  const basePath = baseUrlPath();
  const startToken = extractStartTokenFromUrl();
  const flowContextInitialValue = getInitialApplicationConfiguration();
  const isValidStartToken = startToken ? isStartToken(startToken) : false;
  // this is a workaround to set cookie on the this page
  useEffect(() => {
    if (flowContextInitialValue.appSettings.isSDKEnabled && isMobile && isValidStartToken) {
      httpPost<VerificationStartResponse>('/verify/api/v1/verification/start', {
        start_token: startToken,
        accessed_from_desktop: true,
      });
    }
  }, []);

  handleNewTenant(flowContextInitialValue.tenantId);
  return (
    <div className={`app-root ${(isMobile && 'is-mobile') || ''}`}>
      <BrowserRouter basename={basePath}>
        <CameraContextProvider>
          <FlowContextProvider value={flowContextInitialValue}>
            <App />
          </FlowContextProvider>
        </CameraContextProvider>
      </BrowserRouter>
    </div>
  );
};

const AppWithSentryProfiler = Sentry.withProfiler(AppWithContext, { name: 'VerifyID' });
const dev = isEnvDev();

ReactDOM.render(
  <React.StrictMode>
    {!dev && (
      <Sentry.ErrorBoundary fallback={<ErrorPage />}>
        <AppWithSentryProfiler />
      </Sentry.ErrorBoundary>
    )}
    {dev && <AppWithContext />}
  </React.StrictMode>,
  document.getElementById('root'),
);
