import React from 'react';
import { useTranslation } from 'react-i18next';

import LoadingBlockComponent from '../../components/loading-block/loading-block.component';
import TenantLogoHeader from '../../components/logo-header/logo-header.component';
import PageComponent from '../../components/page/page.component';
import Title from '../../components/title/title.component';

import './processing.page.scss';

const SdkProcessingPage: React.FC = function () {
  const { t } = useTranslation();
  return (
    <PageComponent
      className={'processing-component'}
      title={
        <div className={'page-title'}>
          <TenantLogoHeader />
        </div>
      }
    >
      <Title className={'sub-title'} level={3}>
        {t('verification_in_progress')}
      </Title>
      <LoadingBlockComponent />
      <Title className={'loading-message'} level={4}>
        {t('please_wait')}
      </Title>
    </PageComponent>
  );
};

export default SdkProcessingPage;
