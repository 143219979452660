import UAParser from 'ua-parser-js';
/**
 * IsMobile is written outside and it executed outside of react lifecycle so we detect whether the user is from mobile or not as early as possible (much before react is loaded to the page or it goes throught react's component lifecycle)
 * @param dynamicInnerWidth - number of dynamiclly event base resize innerWidth so we could continulously detect that the user is actually in mobile.
 * @returns boolean
 */
type deviceType = 'mobile' | 'tablet';
const mobileDeviceType: deviceType[] = ['mobile', 'tablet'];
const maxTabletScreenWidth = 1024;

const isUserIsOnMobileDevice = (userAgent: string) =>
  userAgent.toLowerCase().includes('mobile') ||
  userAgent.toLowerCase().includes('tablet') ||
  userAgent.toLowerCase().includes('ios') ||
  userAgent.toLowerCase().includes('ipad');

const isAndroid = (userAgent: string) => userAgent.toLowerCase().includes('android');

const isWidthTablet = () => {
  if (window.innerHeight > window.innerWidth && window.innerWidth < maxTabletScreenWidth) return true;
  if (window.innerHeight < window.innerWidth && window.innerWidth < maxTabletScreenWidth) return true;
  return false;
};

export function isMobileDevice(): boolean {
  if (isUserIsOnMobileDevice(navigator.userAgent)) return true;
  const device = UAParser().device;
  const deviceType = device?.type as deviceType;
  if (mobileDeviceType.includes(deviceType)) return true;
  if (isAndroid(navigator.userAgent) && isWidthTablet()) return true;

  const isMacOs = navigator.userAgent.toLowerCase().includes('mac os');
  // Workaround for iPadOS which is currently not detectable via UA
  const iPadOS = window.orientation !== undefined && isMacOs;
  return iPadOS;
}
