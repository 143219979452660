const FaceOnboardingSvg = (): React.ReactElement => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="face-icon svg-primary-color"
    >
      <path d="M17.7881 17.8887H13.6186C10.7404 17.8887 8.40674 20.221 8.40674 23.1005C8.40674 23.6738 8.8732 24.1429 9.44911 24.1429H21.9576C22.5333 24.1429 23 23.6762 23 23.1005C23 20.221 20.6677 17.8887 17.7881 17.8887Z" />
      <circle cx="15.8351" cy="11.9747" r="4.50982" />
      <path
        opacity="0.4"
        d="M9.4375 27.375H4.625V22.5625C4.625 21.8352 4.03711 21.25 3.3125 21.25C2.58789 21.25 2 21.8352 2 22.5625V28.25C2 29.2163 2.78367 30 3.75 30H9.4375C10.1648 30 10.75 29.4148 10.75 28.6875C10.75 27.9602 10.1648 27.375 9.4375 27.375ZM3.26328 10.75C4.03711 10.75 4.625 10.1648 4.625 9.4375V4.625H9.4375C10.1648 4.625 10.75 4.03711 10.75 3.3125C10.75 2.58789 10.1648 2 9.4375 2H3.75C2.78367 2 2 2.78367 2 3.75V9.4375C2 10.1648 2.58789 10.75 3.26328 10.75ZM28.25 2H22.5625C21.8352 2 21.25 2.58789 21.25 3.26328C21.25 3.93867 21.8352 4.625 22.5625 4.625H27.375V9.4375C27.375 10.1648 27.9602 10.75 28.6875 10.75C29.4148 10.75 30 10.1648 30 9.4375V3.75C30 2.78367 29.218 2 28.25 2ZM28.6875 21.25C27.9629 21.25 27.375 21.8379 27.375 22.5625V27.375H22.5625C21.8379 27.375 21.25 27.9629 21.25 28.6875C21.25 29.4121 21.8352 30 22.5625 30H28.25C29.2163 30 30 29.2163 30 28.25V22.5625C30 21.8352 29.4148 21.25 28.6875 21.25Z"
      />
    </svg>
  );
};

export default FaceOnboardingSvg;
