import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Button from '../../components/button/button.component';
import TenantLogoHeader from '../../components/logo-header/logo-header.component';
import PageComponent from '../../components/page/page.component';
import { useFlowContext } from '../../hooks/verification-flow';
import { eventFactory } from '../../utils/monitoring/event-factory';
import { getCameraPermission, noPermissionRefreshButton } from '../../utils/monitoring/init-ui-monitoring';
import { sendEvent } from '../../utils/monitoring/send-bi';

import CameraPermissionsSvg from './camera-permissions.svg';

import './camera-permissions.component.scss';

export type CameraPermission = 'Allow' | 'Dismissed' | 'Denied';

const CameraPermissionsComponent: React.FC<{
  currentPermission: CameraPermission;
  resetCameraPermission: () => void;
}> = function ({ currentPermission, resetCameraPermission }) {
  const { state } = useFlowContext();
  const { t } = useTranslation();

  // Check if the user is coming from a webview browser
  const isWebView = navigator.userAgent.toLowerCase().includes('wv');

  getCameraPermission('true');
  if (isWebView) {
    noPermissionRefreshButton('true');
  }
  useEffect(() => {
    const permissionDeniedPageName = isWebView ? 'permission_denied_no_refresh_button' : 'permission_denied';
    const eventReport = eventFactory.createPageViewEvent(permissionDeniedPageName);
    sendEvent(state.sessionId, eventReport);
  }, []);

  const onRefreshClick = () => {
    window.location.reload();
    resetCameraPermission();
  };

  return (
    <PageComponent
      className={'camera-permission-page'}
      title={<TenantLogoHeader />}
      footer={
        !isWebView && (
          <div className="button-wrapper">
            <Button type="primary" onClick={onRefreshClick}>
              {t('camera_permissions_refresh_button')}
            </Button>
          </div>
        )
      }
    >
      <p className="camera-permission-title">
        <strong>
          <Trans t={t}>{'camera_permissions_title'}</Trans>
        </strong>
      </p>
      <CameraPermissionsSvg />
      {currentPermission === 'Dismissed' ? (
        <p>
          <Trans t={t}>{'camera_permissions_try_again'}</Trans>
        </p>
      ) : (
        <>
          <p>
            <Trans t={t}>{'camera_permissions_enable_settings'}</Trans>
          </p>
        </>
      )}
    </PageComponent>
  );
};

export default CameraPermissionsComponent;
