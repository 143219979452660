import React from 'react';

import './checkbox.component.scss';

interface CheckboxProps {
  onChange?: () => void;
  className?: string;
  value?: boolean;
  label?: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = function ({ onChange, className, value, label }) {
  const checkbox = (
    <div className={`checkbox-component ${className}`}>
      <input type="checkbox" checked={value} onChange={onChange} />
      <label>{label}</label>
    </div>
  );
  return checkbox;
};

export default Checkbox;
