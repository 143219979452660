import React from 'react';

interface TitleProps {
  children: React.ReactNode;
  level?: 1 | 2 | 3 | 4 | 5;
  className?: string;
  hidden?: boolean;
  style?: React.CSSProperties;
  testId?: string;
}

const Title: React.FC<TitleProps> = ({ children, level = 1, testId, ...props }) => {
  return React.createElement(`h${level}`, { ...props, 'data-auto': testId }, children);
};

export default Title;
