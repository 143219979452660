export class EventFactory {
  createUploadImageEvent(
    step: string,
    requestDuration: string,
    serverProcessingTime: string,
    requestBuildTimeMs: string,
  ) {
    return {
      event_type: 'upload_image',
      upload_image_req: {
        process_result: 'success',
        image_type: step,
        request_total_time_ms: String(requestDuration),
        request_processing_time_ms: String(serverProcessingTime),
        request_build_time_ms: String(requestBuildTimeMs),
      },
    };
  }
  createPageViewEvent(step: string) {
    return {
      event_type: 'page_view',
      page_view_req: {
        page_name: step,
      },
    };
  }
  createProcessingCompleteEvent() {
    return {
      event_type: 'processing_complete',
      processing_complete_req: {
        status: 'complete',
      },
    };
  }
  createUserActionEvent(userActionType: string, pageName: string) {
    return {
      event_type: 'user_action',
      user_action_req: {
        user_action_type: userActionType,
        page_name: pageName,
      },
    };
  }

  createErrorEvent(errorName: string, errorMessage: string, step: string) {
    return {
      event_type: 'error',
      error_req: {
        error_name: errorName,
        error_description: errorMessage,
        step: step,
      },
    };
  }

  createRedirectEvent(redirectUrl: string, pageName: string) {
    return {
      event_type: 'redirect',
      redirect_req: {
        redirect_url: redirectUrl,
        page_name: pageName,
      },
    };
  }
}

export const eventFactory = new EventFactory();
