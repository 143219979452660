/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';

export type Path = { absolute: string; relative: string };

export type Route = {
  /** Path object of the current route */
  path: string;

  /** The component to render for this route */
  element?: React.ReactElement;

  /** Label for the route (mostly for navbar) */
  label: string;

  /** Icon for the route (mostly for navbar) */
  icon?: JSX.Element;

  /** The child routes of this route */
  children?: Record<string, Route>;

  /**
   * Indicator whether this route should use an outlet or not.
   * If true, the children routes will not be rendered, and their rendering respoinsibility
   *  is passed to the route element, using the Outlet component.
   * If false, the children routes will be rendered as pages.
   */
  withOutlet?: boolean;
};

export const routes = {
  onboardingInstructions: {
    path: 'onboarding-instructions',
    label: 'Onboarding Instructions',
  },
  qr: {
    path: 'qr',
    label: 'QR',
  },
  scanDocumentBack: {
    path: 'scan-document-back',
    label: 'Scan Document Back',
  },
  confirmScanDocumentBack: {
    path: 'scan-document-back/confirm',
    label: 'Scan Document Back Confirm',
  },
  verificationProcess: {
    path: 'verification-process',
    label: 'Verification Process',
  },
  startVerification: {
    path: 'start-verification/:sessionId',
    label: 'Start verification',
  },
  errorVerification: {
    path: 'error-verification',
    label: 'Error verification',
  },
  endVerification: {
    path: 'end-verification',
    label: 'end verification',
  },
  scanDocumentFront: {
    path: 'scan-document-front',
    label: 'Scan Document Front',
  },
  confirmScanDocumentFront: {
    path: 'scan-document-front/confirm',
    label: 'Scan Document Front Confirm',
  },
  imageLoader: {
    path: 'image-loader',
    label: 'image loader',
  },
  confirmSelfieImage: {
    path: 'selfie-image/confirm',
    label: 'Selfie Image Confirm',
  },
  selfieImage: {
    path: 'selfie-image',
    label: 'Selfie Image',
  },
  result: {
    path: 'result',
    label: 'Result',
  },
  error: {
    path: 'error',
    label: 'Error',
  },
  timeout: {
    path: 'timeout',
    label: 'Timeout',
  },
  processing: {
    path: 'processing',
    label: 'Processing',
  },
  imageQualityAlert: {
    path: 'image-quality-alert',
    label: 'Image Quality Alert',
  },

  retry: {
    path: 'retry',
    label: 'Retry',
  },
  mobileVerificationComplete: {
    path: 'mobile-verification-complete',
    label: 'Mobile Verification Complete',
  },
  desktopVerificationCompleted: {
    path: 'desktop-verification-completed',
    label: 'Desktop Verification Completed',
  },
  verificationInProgress: {
    path: 'verification-in-progress',
    label: 'Verification In Progress',
  },
  clickToContinue: {
    path: 'click-to-continue',
    label: 'Click To Continue',
  },

  //SDK

  sdkFlow: {
    path: 'sdk-flow',
    label: 'SDK Flow',
  },
  sdkProcessing: {
    path: 'sdk-processing',
    label: 'SDK Processing',
  },
  sdkCameraError: {
    path: 'sdk-camera-permissions-error',
    label: 'SDK Camera Permissions Error',
  },
};
