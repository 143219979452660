const ExitSvg = (): React.ReactElement => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="exit-icon"
    >
      <circle cx="14" cy="14" r="14" fill="#F2F2F2" />
      <path d="M9.33301 9.33398L18.6663 18.6673" stroke="#858286" strokeWidth="1.80222" strokeLinecap="round" />
      <path d="M18.6663 9.33398L9.33293 18.6673" stroke="#858286" strokeWidth="1.80222" strokeLinecap="round" />
    </svg>
  );
};

export default ExitSvg;
