import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useFlowContext } from '../../hooks/verification-flow';
import { routes } from '../../routes/routes.utils';
import CameraPermissionsComponent, { CameraPermission } from '../camera-page/camera-permissions.component';

const SdkCameraPermissionsErrorPage: React.FC = () => {
  const { state } = useLocation();
  const { state: contextState } = useFlowContext();
  const [cameraPermission, setCameraPermission] = useState<CameraPermission>('Allow');

  useEffect(() => {
    const { type } = state as { type: string };
    if (type === 'camera-permission-dismissed') {
      setCameraPermission('Dismissed');
    }
    if (type === 'camera-permission-denied') {
      setCameraPermission('Denied');
    }
    const sdkContainer = document.getElementById('web-sdk-root-container');
    if (sdkContainer) {
      sdkContainer.style.visibility = 'hidden';
    }
  }, [state]);

  const onRefreshClick = () => {
    window.location.href = `${window.location.origin}/verify/app/session/${contextState.sessionId}/${routes.sdkFlow.path}`;
  };
  return (
    <CameraPermissionsComponent
      currentPermission={cameraPermission}
      resetCameraPermission={onRefreshClick}
    ></CameraPermissionsComponent>
  );
};

export default SdkCameraPermissionsErrorPage;
