export const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const validateEmail = (email?: string | number) => {
  if (!emailRegex.test(String(email)) || !email) {
    return 'invalidEmail';
  }
  return null;
};

function isAlphaNumeric(str: string): boolean {
  return /^[a-zA-Z0-9_\-.~]+$/.test(str);
}

export function isValidateToken(token?: string): boolean {
  if (!token || !isAlphaNumeric(token)) return false;
  return true;
}

export const shortIdLength = 6;
export const mediumIdLength = 12;
const infix = 'sId';
const infixIndex = 6;

// Function to validate if a string is base64 encoded
function isBase64(str: string): boolean {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

export function getSessionIdFromStartToken(startToken: string): string | null {
  const base64SessionId = startToken.substring(shortIdLength);
  if (!isBase64(base64SessionId)) {
    return null;
  }
  const sessionId = atob(base64SessionId).toString();
  return sessionId;
}

export function isSessionId(str: string): boolean {
  if (str.length != mediumIdLength + infix.length) {
    return false;
  }
  if (str.substring(infixIndex, infixIndex + infix.length) != infix) {
    return false;
  }
  return true;
}

export function isStartToken(str: string): boolean {
  const sessionId = getSessionIdFromStartToken(str);
  return sessionId !== null && isSessionId(sessionId);
}
