import { QRCodeSVG } from 'qrcode.react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import TenantLogoHeader from '../../components/logo-header/logo-header.component';
import PageComponent from '../../components/page/page.component';
import Text from '../../components/text/text.component';
import Title from '../../components/title/title.component';
import { mobileDetection } from '../../hooks/device-detection';
import { ErrorType, useErrorNavigation } from '../../hooks/error-navigation';
import { SessionStatus, useFlowContext, VerificationStartResponse } from '../../hooks/verification-flow';
import { routes } from '../../routes/routes.utils';
import { StatusRes } from '../../types/verification-status-types';
import { httpGet, httpPost } from '../../utils/http';
import { eventFactory } from '../../utils/monitoring/event-factory';
import { startedSession } from '../../utils/monitoring/init-ui-monitoring';
import { sendEvent } from '../../utils/monitoring/send-bi';
import { captureException } from '../../utils/monitoring/sentry';
import { isValidateToken } from '../../utils/validations';

import './qr.scss';

const Qr: React.FC = function () {
  const { startToken = '' } = useParams();
  const { t } = useTranslation();
  const { isMobile } = mobileDetection();
  const { state } = useFlowContext();
  let checkStatusInterval: NodeJS.Timer;
  let sessionId: string;
  const { handleError, handleTimeout } = useErrorNavigation();

  const navigate = useNavigate();
  const buildRedirectQRUrl = useMemo(() => {
    if (!isValidateToken(startToken)) {
      handleError(new Error('Invalid token'), state.currentStep, state.sessionId);
      return '';
    }
    return `${window.location.origin}/verify/app/${startToken}?startedOnDesktop=true`;
  }, []);

  const parseResponse = (res: { data: StatusRes }) => {
    if (res?.data?.status) {
      state.status = res.data.status as SessionStatus;
      if (res?.data?.status === 'capturing') {
        navigate(`/session/${sessionId}/${routes.verificationInProgress.path}`);
      }
    }
  };

  const pollVerificationStatus = (session_id: string) => {
    let attempt = 1;

    const getStatus = () => {
      httpGet<StatusRes>(`verify/api/v1/verification/${session_id}/status`)
        .then(parseResponse)
        .catch((error) => {
          if (error?.message.includes('Forbidden')) {
            const additionalInfo = { sessionId: session_id, ...error };
            captureException(error as Error, additionalInfo);
            handleTimeout(error as Error, state.currentStep, ErrorType.sessionTimeout, session_id);
          } else {
            attempt++;
            //we want to retry up to 10 calls before we go to an error page
            if (attempt <= 10) {
              getStatus();
            } else {
              const additionalInfo = { sessionId: session_id, ...error };
              captureException(new Error('Request failed'), additionalInfo);
              handleError(error as Error, state.currentStep, session_id);
            }
          }
        });
    };
    getStatus();
  };

  useEffect(() => {
    return () => {
      clearInterval(checkStatusInterval);
    };
  }, []);

  const startStatusPolling = useCallback((sessionId) => {
    checkStatusInterval = setInterval(() => {
      pollVerificationStatus(sessionId);
    }, state.qrScreenPollingInterval);
  }, []);

  useEffect(() => {
    const eventReport = eventFactory.createPageViewEvent('qr');
    sendEvent(startToken, eventReport);
  }, []);

  useEffect(() => {
    // TODO: this should be removed once VER-1793 is solved, session id should be in url
    let attempt = 1;
    const retry = () => {
      httpPost<VerificationStartResponse>('/verify/api/v1/verification/start', {
        start_token: startToken,
        accessed_from_desktop: !isMobile,
      })
        .then((res) => {
          startedSession('true');
          sessionId = res.data.session_id;
          startStatusPolling(res.data.session_id);
        })
        .catch((error) => {
          if (error?.message.includes('Forbidden')) {
            const additionalInfo = { start_token: startToken, ...error };
            captureException(error as Error, additionalInfo);
            handleTimeout(error as Error, state.currentStep, ErrorType.sessionTimeout, undefined);
          } else {
            attempt++;
            if (attempt <= 3) {
              retry();
            } else {
              startedSession('false');
              const additionalInfo = { start_token: startToken, ...error };
              captureException(new Error('Request failed'), additionalInfo);
              handleError(error as Error, state.currentStep, undefined);
            }
          }
        });
    };

    retry();
  }, []);

  return (
    <div className="qr-page-wrapper">
      <PageComponent className="qr-page-container" title={<TenantLogoHeader />}>
        <div className={'desktop'}>
          <Title className="qr-title">{t('verify_identity')}</Title>
          <Text testId="scan_qr_code">{t('scan_qr_code')}</Text>
          {startToken && (
            <QRCodeSVG
              className="qr-code"
              data-auto="qr-code"
              value={buildRedirectQRUrl}
              size={167}
              bgColor={'#FFFFFF'}
              fgColor={'#000000'}
              level={'L'}
              includeMargin={false}
            />
          )}
          <Text testId="or_use_url">{t('or_use_url')}</Text>
          {startToken && (
            <a className={'qr-url'} data-auto="qr-url" href={buildRedirectQRUrl}>
              {buildRedirectQRUrl}
            </a>
          )}
        </div>
      </PageComponent>
    </div>
  );
};

export default Qr;
