import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { eventFactory } from '../../utils/monitoring/event-factory';
import { sendEvent } from '../../utils/monitoring/send-bi';
import Button from '../button/button.component';
import Checkbox from '../checkbox/checkbox.component';
import Instruction from '../instruction/instruction.component';
import Text from '../text/text.component';
import Title from '../title/title.component';

import CalendarOnboardingSvg from './calendar.svg';
import FaceOnboardingSvg from './face.svg';
import IdCardOnboardingSvg from './id-card.svg';
import ListOnboardingSvg from './list.svg';

import './one-page-onboarding.component.scss';

interface OnePageOnboardingProps {
  handleExit: () => void;
  isCheckboxConsent?: boolean;
}

const OnePageOnboarding: React.FC<OnePageOnboardingProps> = ({ handleExit, isCheckboxConsent }) => {
  const { t } = useTranslation();
  const { startToken = '' } = useParams();
  const [consentChecked, setConsentChecked] = useState(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      handleExit();
    }
  };
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleExit();
  };

  const approvedConsent = () => {
    setConsentChecked(!consentChecked);
    if (!consentChecked) {
      const eventReport = eventFactory.createUserActionEvent('consent_checked', 'intro');
      sendEvent(startToken, eventReport);
    }
  };

  const consentCheckboxLabel = (
    <span>
      {t('i_agree')}{' '}
      <a
        href="https://www.transmitsecurity.com/legal/transmit-security-terms-of-service"
        target="_blank"
        rel="noreferrer"
      >
        {t('terms_and_conditions')}
      </a>{' '}
      {t('and_the')}{' '}
      <a
        href="https://www.transmitsecurity.com/legal/transmit-security-privacy-statement"
        target="_blank"
        rel="noreferrer"
      >
        {t('privacy_policy')}
      </a>
    </span>
  );

  return (
    <div className={'one-page-onboarding'}>
      <div className={'instructions-wrapper'}>
        <div className={'general-instruction'}>
          <Title className={'general-instruction-title'} testId="general-instruction-title">
            {t('prepare_for_verification')}
          </Title>
          <Text className={'general-instruction-description'} testId="general-instruction-description">
            {t('verification_description_instruction')}
          </Text>
        </div>
        <div className={'onboarding-instructions-section'} data-auto="onboarding-instructions-section">
          <Instruction
            Icon={IdCardOnboardingSvg}
            instructionTitle={t('use_valid_document_full')}
            testId="use_valid_document"
          />
          <Instruction
            Icon={CalendarOnboardingSvg}
            instructionTitle={t('document_not_expired')}
            testId="document_not_expired"
          />
          <Instruction
            Icon={ListOnboardingSvg}
            instructionTitle={t('capturing_instructions')}
            testId="capturing_instructions"
          />
          <Instruction Icon={FaceOnboardingSvg} instructionTitle={t('no_photo_copies')} testId="no_photo_copies" />
        </div>
      </div>
      {isCheckboxConsent && (
        <Checkbox
          className={'consent-checkbox'}
          value={consentChecked}
          onChange={approvedConsent}
          label={consentCheckboxLabel}
        ></Checkbox>
      )}
      <div className={'continue-button'} onKeyDown={handleKeyDown}>
        <Button testId="continue-button" onClick={handleButtonClick} disabled={isCheckboxConsent && !consentChecked}>
          {t('start')}
        </Button>
      </div>
    </div>
  );
};

export default OnePageOnboarding;
